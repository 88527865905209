<template>
	<div class="dash-subheader py-2 py-lg-12 subheader-transparent" v-bind:class="subheaderClasses" id="kt_subheader">
		<div class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap container">
			<div class="d-flex align-items-center flex-wrap mr-2">
				<!--begin::Heading-->
				<div class="d-flex flex-column">
					<!--begin::Title-->
					<h2 class="text-white my-2 mr-5" style="font-size: 2.5em; font-weight: 200;">
						{{ title }}
					</h2>
				</div>
			</div>
			<div class="d-flex align-items-center">
				<!--begin::Button-->
				<router-link :to="{ name: 'srflist' }" v-if="['admin', 'noc', 'staff'].includes(currentUser.role) || currentUser.showsrf == true">
					<span class="btn btn-white font-weight-bold py-3 px-6 mr-2">
						SRF List
					</span>
				</router-link>
				<router-link :to="{ name: 'admin' }" v-if="['admin', 'noc', 'staff'].includes(currentUser.role)">
					<span class="btn btn-white font-weight-bold py-3 px-6 mr-2">
						Admin
					</span>
				</router-link>
				<!--end::Button-->
			</div>
		</div>
	</div>
</template>

<style lang="scss">
.custom-v-dropdown {
	&.dropdown-toggle {
		padding: 0;
		&:hover {
			text-decoration: none;
		}

		&.dropdown-toggle-no-caret {
			&:after {
				content: none;
			}
		}
	}

	&.dropdown-menu {
		margin: 0;
		.b-dropdown-text {
			padding: 0;
		}
	}
}
</style>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'KTDashSubheader',
	props: {
		title: String,
	},
	computed: {
		...mapGetters(['layoutConfig', 'currentUser']),

		/**
		 * Check if subheader width is fluid
		 */
		widthFluid() {
			return false;
		},

		subheaderClasses() {
			const classes = [];
			const style = this.layoutConfig('subheader.style');
			if (style) {
				classes.push(style);

				if (style === 'solid') {
					classes.push('bg-white');
				}

				if (this.layoutConfig('subheader.fixed')) {
					classes.push('border-top');
				}
			}
			return classes.join(' ');
		},
	},
};
</script>
