<template>
	<!-- begin:: Header -->
	<div id="kt_header" ref="kt_header" class="dash-header" v-bind:class="[{ 'viewing-as-header': viewAs.AccountID }, headerClasses]">
		<div class="row align-items-center" v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }">
			<div class="col justify-self-start d-flex align-items-stretch ml-4">
				<div class="header-logo">
					<router-link to="/">
						<img alt="Logo" :src="layoutConfig('self.logo.dark')" class="logo-default max-h-50px" />
						<!-- <img alt="Logo" :src="layoutConfig('self.logo.sticky')" class="logo-sticky max-h-50px" /> -->
					</router-link>
				</div>
			</div>
			<div class="view-as-header align-self-start">
				<ViewAsHeader />
			</div>
			<div class="col">
				<KTTopbar></KTTopbar>
			</div>
		</div>
	</div>
	<!-- end:: Header -->
</template>

<script>
import { mapGetters } from 'vuex';
import KTTopbar from '@/view/layout/header/Topbar.vue';
// import KTLayoutDashHeader from '@/assets/js/layout/base/dash-header.js';
// import KTLayoutHeaderMenu from '@/assets/js/layout/base/header-menu.js';

export default {
	name: 'KTDashHeader',
	components: {
		KTTopbar,
		ViewAsHeader: () => import('@/view/layout/header/ViewAsHeader.vue'),
	},
	mounted() {
		// Init Desktop & Mobile Headers
		// KTLayoutDashHeader.init('kt_header', 'kt_header_mobile');
		// Init Header Menu
		// KTLayoutHeaderMenu.init(this.$refs['kt_header_menu'], this.$refs['kt_header_menu_wrapper']);
	},
	computed: {
		...mapGetters(['layoutConfig', 'getClasses', 'viewAs']),
		headerMenuEnabled() {
			return !!this.layoutConfig('header.menu.self.display');
		},
		headerClasses() {
			const classes = this.getClasses('header');
			if (typeof classes !== 'undefined') {
				return classes.join(' ');
			}
			return null;
		},
		headerMenuClasses() {
			const classes = this.getClasses('header_menu');
			if (typeof classes !== 'undefined') {
				return classes.join(' ');
			}
			return null;
		},
		widthFluid() {
			return this.layoutConfig('header.self.width') === 'fluid';
		},
	},
};
</script>

<style scoped lang="scss">
.dash-header {
	z-index: 10;
}
.viewing-as-header {
	border-top: 2px solid #fec82e;
}
</style>
