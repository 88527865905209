<template>
	<div class="d-flex flex-column flex-root" v-if="isAuthenticated">
		<KTHeaderMobile></KTHeaderMobile>
		<div class="d-flex flex-row flex-column-fluid page">
			<div id="kt_wrapper" class="d-flex flex-column flex-row-fluid wrapper">
				<AlertBanner v-if="alertBanner.show" :message="alertBanner.message" />
				<KTDashHeader></KTDashHeader>
				<div id="kt_content" class="content d-flex flex-column flex-column-fluid">
					<KTDashSubheader v-if="subheaderDisplay" v-bind:title="pageTitle" />
					<div class="d-flex flex-column-fluid">
						<div :class="{ 'container-fluid': contentFluid, container: !contentFluid }">
							<div class="d-lg-flex flex-row-fluid">
								<div class="content-wrapper flex-row-fluid">
									<transition name="fade-in-up">
										<router-view />
									</transition>
								</div>
							</div>
						</div>
					</div>
				</div>
				<KTFooter></KTFooter>
			</div>
		</div>
		<KTStickyToolbar v-if="toolbarDisplay"></KTStickyToolbar>
		<KTScrollTop></KTScrollTop>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import KTDashHeader from '@/view/layout/header/DashHeader.vue';
import KTHeaderMobile from '@/view/layout/header/HeaderMobile.vue';
import AlertBanner from '@/view/layout/header/AlertBanner.vue';
import KTFooter from '@/view/layout/footer/Footer.vue';
import HtmlClass from '@/core/services/htmlclass.service';
import KTDashSubheader from '@/view/layout/subheader/DashSubheader.vue';
import KTStickyToolbar from '@/view/layout/extras/StickyToolbar.vue';
import KTScrollTop from '@/view/layout/extras/ScrollTop';
// import Loader from '@/view/content/loaders/Loader.vue';
import { ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME } from '@/core/services/store/htmlclass.module.js';

export default {
	name: 'DashLayout',
	components: {
		KTDashHeader,
		KTHeaderMobile,
		AlertBanner,
		KTFooter,
		KTDashSubheader,
		KTStickyToolbar,
		KTScrollTop,
		// Loader,
	},
	beforeMount() {
		// show page loading
		this.$store.dispatch(ADD_BODY_CLASSNAME, 'page-loading');

		// initialize html element classes
		HtmlClass.init(this.layoutConfig());
	},
	mounted() {
		// check if current user is authenticated
		if (!this.isAuthenticated) {
			this.$router.push({ name: 'login' });
		} else {
			this.$store.dispatch(ADD_BODY_CLASSNAME, 'dash-background');
		}
	},
	beforeDestroy() {
		this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'dash-background');
	},
	computed: {
		...mapGetters(['isAuthenticated', 'breadcrumbs', 'pageTitle', 'layoutConfig', 'ready', 'alertBanner']),

		loaderEnabled() {
			return !/false/.test(this.layoutConfig('loader.type'));
		},

		contentFluid() {
			return false;
		},

		loaderLogo() {
			return process.env.BASE_URL + this.layoutConfig('loader.logo');
		},

		asideEnabled() {
			return !!this.layoutConfig('aside.self.display');
		},

		toolbarDisplay() {
			// return !!this.layoutConfig("toolbar.display");
			return true;
		},

		subheaderDisplay() {
			return !!this.layoutConfig('subheader.display');
		},
	},
	watch: {
		ready() {
			if (this.ready) {
				this.$store.dispatch(REMOVE_BODY_CLASSNAME, 'page-loading');
			}
		},
	},
};
</script>
